$(() => {
  const element = $('[data-focus-within]');
  element.focusin((e) => {
    $(e.target.closest('[data-focus-within]')).attr(
      'data-focus-within',
      'true',
    );
  });
  element.focusout((e) => {
    const nextFocus = $(e.relatedTarget).closest('[data-focus-within]');
    const currentFocus = $(e.target).closest('[data-focus-within]');
    if (!nextFocus.is(currentFocus)) {
      currentFocus.attr('data-focus-within', 'false');
    }
  });
});
