$(() => {
  const gridViewContainer = $('#publications-grid-view-container');
  const listViewContainer = $('#publications-list-view-container');
  const gridButton = $('#publications-grid-display-option-button');
  const listButton = $('#publications-list-display-option-button');
  let selectedOption = localStorage.getItem('selected-publications-option');
  if (selectedOption == null) {

    selectedOption = 'grid';
  }

  updateView(selectedOption);

  gridButton.on('click', function() {
    const value = $(this).data('display');
    updateView(value);
  });

  listButton.on('click', function() {
    const value = $(this).data('display');
    updateView(value);
  });

  function updateView(option) {
    localStorage.setItem('selected-publications-option', option);
    console.log(option);
    if (option === 'list') {

      listViewContainer.show();
      gridViewContainer.hide();
      updateButtons(listButton, gridButton);
    } else {

      gridViewContainer.show();
      listViewContainer.hide();
      updateButtons(gridButton, listButton);
    }
  }

  function updateButtons(buttonToSelect, buttonToUnselect) {
    let src = buttonToSelect.attr('src');
    if (src !== undefined) {

      buttonToSelect.attr('src', src.replace('-unselected', '-selected'));
      src = buttonToUnselect.attr('src');
      buttonToUnselect.attr('src', src.replace('-selected', '-unselected'));
    }
  }
});
