$(() => {
  const telInput = $('[data-intl-tel-input]');

  telInput.each((index, element) => {
    ($(element) as any).intlTelInput({
      hiddenInput: $(element).attr('name'),
      utilsScript: '/assets/vendor/intl-tel-input/build/js/utils.js',
    });
  });

  telInput.keyup(onTelInputKeyUp);

  function onTelInputKeyUp() {
    const isEmpty = !$(this).val();
    const isValid = ($(this) as any).intlTelInput('isValidNumber');
    const errorMsg = $(this)
      .closest('.umbraco-forms-field-wrapper')
      .find('[data-intl-tel-error]');

    $(this).removeClass('error');
    errorMsg.addClass('hide');

    if (!isEmpty && !isValid) {
      telInput.addClass('error');
      errorMsg.removeClass('hide');
    }
  }
});
