export enum MessageType {
  Default,
  Info,
  Success,
  Warning,
  Error,
}

let close = 'close';

export function addMessage(
  message: string,
  messageType: MessageType = MessageType.Default,
  onClose?: () => void,
) {
  const element = $(`
    <div class="message message-${MessageType[messageType].toLowerCase()}">
      <div class="message-container container grid-xl">
        <div class="message-content">${message}</div>
      </div>
    </div>
  `);

  const button = $(`
    <button class="message-close-btn btn btn-icon">
      <span class="text-assistive">${close}</span>
      <i class="icon-close" />
    </button>
  `);

  button.click((e) => {
    $(e.target)
      .closest('.message')
      .slideUp('100', () => {
        $(this).remove();
      });
    if (onClose) {
      onClose();
    }
  });

  element.children('.message-container').append(button);

  $('#message-hub').append(element);
}

$(() => {
  close = $('#message-hub').data('closeBtnText');
});
