import * as messageHub from './messageHub';

export interface CookieConsentMessageProps {
  container: HTMLElement;
}

function cookieExists() {
  return document.cookie.match(/CookieConsent=/);
}

function setCookie() {
  const d = new Date();
  d.setFullYear(d.getFullYear() + 10);
  document.cookie = `CookieConsent=;expires=${d.toUTCString()};Path=/`;
}

$(() => {
  if (cookieExists()) {
    return;
  }

  messageHub.addMessage(
    $('#message-hub').data('cookieConscentMessage'),
    messageHub.MessageType.Default,
    setCookie,
  );
});
