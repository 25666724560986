$(() => {
  const backToTopButton = document.getElementById('back-to-top');

  backToTopButton.addEventListener('click', () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  });

  window.onscroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      backToTopButton.classList.add('is-active');
    } else {
      backToTopButton.classList.remove('is-active');
    }
  };
});
