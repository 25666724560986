$(() => {
  let lastScrollTop = 0;
  const navbar = $('#header')[0];
  if (navbar) {
    $(window).scroll(() => {
      const scrollTop = $(window).scrollTop();

      if (window.pageYOffset > 0) {
        navbar.classList.add('header-sticky');
      } else {
        navbar.classList.remove('header-sticky');
      }

      if (scrollTop > lastScrollTop) {
        $('.nav-primary-btn-search.is-active').click();
      }
      lastScrollTop = scrollTop;
    });
  }
});
