$(() => $('[data-collapse-target]').click(toggleCollapse));

function toggleCollapse(e: JQueryEventObject) {
  const self = $(this);
  const focusTarget = self.data('set-focus');

  e.preventDefault();
  e.stopPropagation();

  $(self.data('collapse-target')).toggleClass('is-active');
  self.toggleClass('is-active');
  if (focusTarget) {
    $(focusTarget).focus();
  }
}
